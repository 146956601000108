import logo from './logo.svg';
import './Audio.css';

// import AudioPlayer from './AudioPlayer';
import { useRef, useState } from 'react';
import axios from 'axios';

import PodcastChecker from './PodcastChecker';
import PodcastInfo from './PodcastInfo';

import H5AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
// import 'react-h5-audio-player/lib/styles.less' Use LESS
// import 'react-h5-audio-player/src/styles.scss' Use SASS

import React, { useEffect } from 'react';
import TwoColumns from './TwoColumns';
import SettingsDropdown from './SettingsDropdown';

// import FeedsTabs from './FeedsTabs';
import { apiBase, getApiData } from './api';

function Audio({ idToken }) {
  // const audioPlayerRef = useRef(null);
  const [podcastUrl, setPodcastUrl] = useState(null);
  const [podcasts, setPodcasts] = useState([]);
  const [podcastCategories, setPodcastCategoriesState] = useState([]);
  const [podcastInfo, setPodcastInfoState] = useState({});
  const [podcastCurrentTime, setPodcastCurrentTime] = useState(0);

  const [username, setUsername] = useState(localStorage.getItem('username'));
  const [checkPodcasts, setCheckPodcasts] = useState(username !== null);

  const [targetAudience, setTargetAudience] = useState();
  const [language, setLanguage] = useState();
  const [voice, setVoice] = useState();

  useEffect(() => {
    const podcastCategoryListUrl = `${apiBase()}/m/fetch_user_data/` + username;

    axios.get(podcastCategoryListUrl, {
      headers: {
        'Auth': `Bearer ${idToken}`
      }
    }).then(async (fetchResponse) => {
      const userData = fetchResponse.data;

      const targetAudience = userData.targetAudience;
      const language = userData.podcastLanguage;
      const voice = userData.voice;

      setTargetAudience(targetAudience);
      setVoice(voice);
      setLanguage(language);

      console.log("SETTING TARGET AUDIENCE (" + targetAudience + ")");
    });

    return () => {
    };
  }, []);

  const setPodcastSource = (podcastSource) => {
    console.log("setPodcastSource");

    setPodcastUrl(podcastSource);
  }

  const setPodcastList = (podcasts) => {
    console.log("setPodcastList");

    setPodcasts(podcasts);
  }

  const setPodcast = (podcast) => {
    console.log("setPodcast");
    console.log(podcast);

    setPodcastInfoState({ 
      category: podcast.startDatetime,
      mp3MetaData: podcast.mp3MetaData,
    });

    const podcastCategoryListUrl = `${apiBase()}/m/fetch_podcast_categories/` + podcast.podcastId; //mike.davis.20230422.220701";

    console.log(podcastCategoryListUrl);

    fetch(podcastCategoryListUrl).then(async (fetchResponse) => {
      const podcastCategoryList = await fetchResponse.json();

      console.log(podcastCategoryList);

      setPodcastCategoriesState(podcastCategoryList);
    });
  }

  const setPodcastCategory = (podcastCategoryItem) => {
    console.log(podcastCategoryItem);

    const podcastSourceUrl = `${apiBase()}/a/` + username + "/" + podcastCategoryItem.podcastId + "/" + podcastCategoryItem.category + "/a.mp3";

    console.log(podcastSourceUrl);
    setPodcastSource(podcastSourceUrl);
    setPodcastInfoState({
      category: podcastCategoryItem.category,
      mp3MetaData: podcastCategoryItem.mp3MetaData,
    });
  };

  const setTitle = (title) => {
    console.log("Setting title to (" + title + ")");
    document.title = title;
  };

  const handleListen = (e) => {
    //setCurrentTime(e.target.currentTime);
    const currentTime = e.target.currentTime;

    setPodcastCurrentTime(currentTime)
    console.log(currentTime);
  };




  
  return (
    <div className="Audio">


<header className="header">
            <div className="column">
            <h1>DODGYMIKE'S MORNING NEWS</h1>
            </div>
            <div className="column">
            <i className="fa fa-cog" aria-hidden="true"></i>
                <SettingsDropdown 
                username={username} 
                setUsername={setUsername} 
                setCheckPodcasts={setCheckPodcasts} 
                targetAudience={targetAudience} 
                setTargetAudience={setTargetAudience} 
                voice={voice} 
                setVoice={setVoice} 
                language={language} 
                setLanguage={setLanguage}
              ></SettingsDropdown>
            </div>
        </header>

      <PodcastInfo podcastInfo={podcastInfo} podcastCurrentTime={podcastCurrentTime} setTitle={setTitle}></PodcastInfo>
      <H5AudioPlayer
        autoPlay
        src={podcastUrl}
        onPlay={e => console.log("onPlay")}
        onListen={handleListen}
        showSkipControls="true"
        // other props here
      />
      <TwoColumns podcasts={podcasts} setPodcast={setPodcast} podcastCategories={podcastCategories} setPodcastCategory={setPodcastCategory}></TwoColumns>
      {checkPodcasts && <PodcastChecker setPodcastList={setPodcastList} setPodcastSource={setPodcastSource} username={username} idToken={idToken}/>}
    </div>
  );
}

export default Audio;
