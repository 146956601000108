import axios from 'axios';

export function apiBase() {
    return 'https://1jihg7b36k.execute-api.eu-west-1.amazonaws.com/20230325-002';
    // return 'https://mike.elasticninja.com/20230325-002';
};

export function getApiData(url, idToken, refreshToken, setIsLoggedIn) {
    try {
        // console.log("getApiData");
        // const rv = axios.get(url, {
        //     headers: {
        //     'Auth': `Bearer ${idToken}`
        //     }
        // });
        // console.log(`rv (${rv})`);

        const handleErrors = (error) => {
            console.log("ERROR GETTING API DATA");
            // setIsLoggedIn(false);

            throw(error);
        };

        const axiosPromise = axios.get(url, {
            headers: {
            'Auth': `Bearer ${idToken}`
            }
        });

        // axiosPromise.catch(error => {
        //     console.log("ERROR GETTING API DATA");
        //     setIsLoggedIn(false);

        //     throw(error);
        // });

        return axiosPromise; //.catch(handleErrors);

        // console.log(`axiosPromise (${axiosPromise})`);

        // return axiosPromise;
    } catch(error) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.log(error.response.config);

        if(error.response.status === 401) {
            console.log("401");

            const refreshUrl = `${apiBase()}/m/refresh_token`;
            console.log(refreshUrl);

            axios.post(refreshUrl, {
                refreshToken: refreshToken
            }).then(async (fetchResponse) => {
                console.log(fetchResponse.data);
                console.log(fetchResponse.data.idToken);
                console.log(fetchResponse.data.refreshToken);

                localStorage.setItem('idToken', fetchResponse.data.idToken);
                localStorage.setItem('refreshToken', fetchResponse.data.refreshToken);

                setIsLoggedIn(true);
            });
        } else {
            console.error("ERROR GETTING API DATA");
            setIsLoggedIn(false);

            throw(error);
        }
    }
};

