import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { apiBase, getApiData } from './api';

const FormData = require('form-data');

// // Save state
// sessionStorage.setItem('myState', JSON.stringify(state));

// // Load state
// const savedState = JSON.parse(sessionStorage.getItem('myState'));


const LoginPage = ({setIsLoggedIn, setIdToken, setRefreshToken}) => {
  const [username, setUsername] = useState(sessionStorage.getItem('auth-username'));
  const [password, setPassword] = useState('');

  const loginUrl = `${apiBase()}/m/login`;
  var authIdToken = '';
  var refreshToken = '';

  useEffect(() => {
    // Check session data on component mount
    authIdToken = sessionStorage.getItem('auth-id-token');
    
    if (authIdToken) {
      setIdToken(authIdToken);
    }

    refreshToken = sessionStorage.getItem('auth-refresh-token');
    if(refreshToken) {
      setRefreshToken(refreshToken);
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    // Do something with the form values
    console.log(`Username: ${username}, Password: ${password}`);

    let formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    axios.post(loginUrl, formData).then(async (fetchResponse) => {
      console.log(fetchResponse);
      // const authData = (await fetchResponse.json());
      const authData = fetchResponse.data.authDetails;

      if(authData.idToken) {
        setIdToken(authData.idToken);
        setIsLoggedIn(true);

        sessionStorage.setItem('auth-username', username);
        sessionStorage.setItem('auth-id-token', authData.idToken);
        sessionStorage.setItem('auth-refresh-token', refreshToken);
      } else {
        setIsLoggedIn(false);
      }
    }).catch(e => {
      console.error(`WUT (${e})`);
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          Username:
          <input
            type="text"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
        </label>
        <br />
        <label>
          Password:
          <input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </label>
        <br />
        <button type="submit">Log In</button>
      </form>
    </div>
  );
};

export default LoginPage;
