// src/ButtonList.js
import React from 'react';

const PodcastInfo = ({ podcastInfo, podcastCurrentTime, setTitle }) => {
    function secondsToMinutesSeconds(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);

        const paddedMinutes = String(minutes).padStart(2, '0');
        const paddedSeconds = String(remainingSeconds).padStart(2, '0');

        return paddedMinutes + ':' + paddedSeconds;
      }

    const findCurrentMetaData = (podcastInfo, podcastCurrentTime) => {
        var subCategory = "UNKNOWN";

        if(!podcastInfo.mp3MetaData) {
            return subCategory;
        }

        const matchingPodcastInfos = podcastInfo.mp3MetaData.filter((info) => (podcastCurrentTime <= info.end) && (podcastCurrentTime >= info.start));

        console.log(matchingPodcastInfos);
        if(matchingPodcastInfos.length > 0) {
            subCategory = matchingPodcastInfos[0].subCategory;
        }

        const finalInfo = podcastInfo.mp3MetaData[podcastInfo.mp3MetaData.length - 1];

        const title = secondsToMinutesSeconds(podcastCurrentTime) + "/" + secondsToMinutesSeconds(finalInfo.end) + ": " + podcastInfo.category + " - " + subCategory;
        setTitle(title);

        return title; //subCategory;
    };

  return (
    <div class="NowPlaying">
      <div>{findCurrentMetaData(podcastInfo, podcastCurrentTime)}</div>
    </div>
  );
};

export default PodcastInfo;
