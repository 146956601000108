import React, { useState, useEffect } from 'react';
import Audio from "./Audio"
import HourlyCountGraph from './HourlyCountGraph';
import { GeneralCategories } from './GeneralCategories';
import { Regions } from './Regions';
import { apiBase, getApiData } from './api';
import axios from 'axios';
import GridLayout from "react-grid-layout";
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import CategoryGrid from './CategoryGrid';
import 'react-data-grid/lib/styles.css';
import { Chrono } from "react-chrono";

import DataGrid from 'react-data-grid';

const Tab = ({ title, onTabClick, isActive }) => (
  <button onClick={onTabClick} style={isActive ? { fontWeight: 'bold' } : {}}>
    {title}
  </button>
);

const FeedsTabs = ({ idToken, refreshToken, setIsLoggedIn, isLoggedIn }) => {
  const [activeTab, setActiveTab] = useState('Monkeys');
  const [tabItems, setTabItems] = useState([]);
  const [allTopicData, setTopicData] = useState({});
  const [categoryGridData, setCategoryGridData] = useState([]);
  const [dateCategoryGridData, setDateCategoryGridData] = useState([]);
  
  const [feedData, setFeedData] = useState({
    mainRegion: {},
    generalCategory: {},
    publishedDateHour: {},
  });

  var [timelineItems, setTimelineItems] = useState([ ]);

  const handleTimelineItemSelected = (item) => {
    console.log(`handleTimelineItemSelected: ${JSON.stringify(item)}`);

    if(item.title === 'All') {
      setDateCategoryGridData(categoryGridData);
    } else {
      const selectedCategoryGridData = categoryGridData.filter((gridItem) => gridItem.published_date === item.title);
      setDateCategoryGridData(selectedCategoryGridData);
      console.log(categoryGridData)
    }
  };

  const handleTabButtonClick = (tabName) => {
    if(tabName in ['Audio',
    'Categories',
    'Persons',]) {
      return;
    }

    // const feedTopicUrl = `${apiBase()}/m/get_feed_topic_rows/${tabName}`;

    // getApiData(feedTopicUrl, idToken, refreshToken, setIsLoggedIn).then(async (fetchResponse) => {
    //   const topicData = fetchResponse.data;

    //   console.log(topicData);
    //   console.log(topicData.generalCategory);

    //   setFeedData(topicData);
    //   setActiveTab(tabName);
    // });

    const selectedCategoryGridData = allTopicData.filter((item) => item.general_category === tabName);
    setCategoryGridData(selectedCategoryGridData);
    setDateCategoryGridData(selectedCategoryGridData);
    console.log(categoryGridData)
    setActiveTab(tabName);


    const timelineDates = {};

    for(var item of selectedCategoryGridData) {
      timelineDates[item.published_date] ||= 0;
      timelineDates[item.published_date] += 1;
    }

    const curTimelineItems = Object.keys(timelineDates).sort().map((date) => { return { title: date } });
    curTimelineItems.unshift({ title: 'All' });

    setTimelineItems(curTimelineItems);
  };

  const [selectedCellData, setSelectedCellData] = useState(null);


  useEffect(() => {
    const today = new Date();
    const todayLimited = today.toISOString().slice(0, 10);
    const startDate = new Date(today.getTime() - (4 * 24 * 60 * 60 * 1000));
    const startDateLimited = startDate.toISOString().slice(0, 10);

    const feedTopicSummaryUrl = `${apiBase()}/m/get_feed_topic_rows_with_date/${startDateLimited}`;

    getApiData(feedTopicSummaryUrl, idToken, refreshToken, setIsLoggedIn).then(async (fetchResponse) => {
      const responseData = fetchResponse.data;
      const topicDataUrl = responseData.url;
      console.log(topicDataUrl);

      // fetch the topic data from the URL using axios
      const topicDataResponse = await axios.get(topicDataUrl);
      console.log(topicDataResponse);
      const topicData = topicDataResponse.data;
      console.log(topicData);
      // console.log(topicData.generalCategory);

      const timelineDates = {};

      const generalCategoryCounts = {};
      for(var item of topicData) {
        generalCategoryCounts[item.general_category] ||= 0;

        generalCategoryCounts[item.general_category] += 1;

        if(todayLimited < item.published_date) {
          item.published_date = todayLimited;
        }

        timelineDates[item.published_date] ||= 0;
        timelineDates[item.published_date] += 1;
      }

      const curTimelineItems = Object.keys(timelineDates).sort().map((date) => { return { title: date } });
      curTimelineItems.unshift({ title: 'All' });
      setTimelineItems(curTimelineItems);

      console.log(`timelineItems (${JSON.stringify(timelineItems)}) curTimelineItems (${JSON.stringify(curTimelineItems)})`);

      // const generalCategories = Object.keys(topicData.generalCategory).sort((a,b) => topicData.generalCategory[b] - topicData.generalCategory[a]);
      // const generalCategories = topicData.map((item) => item.general_category);

      const generalCategories = Object.keys(generalCategoryCounts).sort((a,b) => generalCategoryCounts[b] - generalCategoryCounts[a]);
      console.log(generalCategories);

      var generalCategoriesTabs = generalCategories.filter((generalCategory) => generalCategoryCounts[generalCategory] > 10); //generalCategories.slice(0, 20);
      generalCategoriesTabs = generalCategoriesTabs.slice(0, 50);

      console.log(generalCategoriesTabs);

      setTopicData(topicData);
      setTabItems([
        'Audio',
        'Categories',
        'Persons',
        ...generalCategoriesTabs
      ]);
    }).catch(e => {
      console.error(e);

      setIsLoggedIn(false);
    });

    return () => {
    };
  }, [ setIsLoggedIn, isLoggedIn ]);

  return (
    <div>
      {tabItems.map((tabName) => (
        <Tab
          key={tabName}
          title={tabName}
          onTabClick={() => handleTabButtonClick(tabName)}
          isActive={tabName === activeTab}
        />
      ))}

      {(activeTab === 'Audio') && <Audio idToken={idToken}></Audio>}

      {(activeTab) && (activeTab !== 'Audio') &&
        <div key='activeTabBody'>
          {/* <GridLayout
            className="layout"
            // layout={layout}
            cols={2}
            rowHeight={300}
            width={1200}
          >
            <GeneralCategories 
              key='general-categories-toplevel' 
              generalCategories={feedData.generalCategory} 
              data-grid={{ x: 0, y: 0, w: 1, h: 1, maxW: 100, static: false }}
            />
            <HourlyCountGraph 
              key='hourly-count-graph-toplevel' 
              idToken={idToken} 
              countsPerHour={feedData.publishedDateHour}
              data-grid={{ x: 1, y: 0, w: 1, h: 1, maxW: 100, static: false }}
            />
            <Regions 
              key='regions-toplevel' 
              regions={feedData.mainRegion}
              data-grid={{ x: 0, y: 1, w: 1, h: 1, static: true }}
            />
          </GridLayout> */}
          <CategoryGrid 
            generalCategories={feedData.generalCategory}
            categoryGridData={dateCategoryGridData}
            mainRegion={feedData.mainRegion}
            setSelectedCellData={setSelectedCellData}
            idToken={idToken}
          />

          <Chrono 
            cardLess={true} 
            items={timelineItems} 
            mode="HORIZONTAL"
            hideControls={true}
            allowDynamicUpdate={true}
            onItemSelected={handleTimelineItemSelected}
          >  
          </Chrono>

          <Details cellData={selectedCellData} />

        </div>
      }
    </div>
  );
};

const LinkFormatter = ({ row, column }) => {
  const cellValue = row[column.key];
  const cellLink = row['link'];

  if(cellLink) {
    return <a href={cellLink} target="_blank" rel="noopener noreferrer">{cellValue}</a>;
  } else {
    return {cellValue};
  }
};

function Details({ cellData }) {
  if (!cellData) return <div>Click a cell to see details...</div>;

  const keys = ['feed_id', 'single_line_tldr', 'primary_company', 'primary_person', 'sales_promotion', 'sentiment', 'technical_level', 'link'];

  const columns = keys.map((item) => {
    const columnDefinition = ({ key: item, name: item, frozen: false, resizable: true });

    if(item === 'single_line_tldr') {
      columnDefinition.renderCell = LinkFormatter;
    }

    return (columnDefinition);
  });

  console.log(cellData);
  console.log(cellData.category);
  console.log(cellData.rows[0]);
  const rows = cellData.rows.filter(
    // (row) => (row.category === cellData.category)
    (row) => 
      (
        (row.category === cellData.category) 
        || (cellData.category === 'ALL')
      )
      && 
      (
        ((row.primary_person === cellData.primary) || (row.primary_company === cellData.primary))
        || (cellData.primary === 'ALL')
      )
  );
  console.log(rows);
  // const rows = [];

  // for(var row of cellData.rows) {
  //   const rowData = {};
  //   for(var rowKey of keys) {
  //     rowData[rowKey] = row[rowKey];
  //   }

  //   rows.push(rowData);
  // }

    //       {cellData.rows.map((row) => (
  //         <tr>
  //           <td>{row.feedId}</td>
  //           <td>{row.primaryCompany}</td>
  //           <td>{row.primaryPerson}</td>
  //           <td>{row.salesPromotion}</td>
  //           <td>{row.sentiment}</td>
  //           <td>{row.singleLineTldr}</td>
  //           <td>{row.technicalLevel}</td>
  //         </tr>
  //       ))}

  return (
  <div>
    <h2>Details</h2>
    <p>Category: {cellData.category}</p>
    <p>Region: {cellData.region}</p>
    <p>Value: {cellData.value}</p>
    <DataGrid
      columns={columns}
      rows={rows}
      ></DataGrid>
  </div>
  )

  // return (
  //   <div>
  //     <h2>Details</h2>
  //     <p>Category: {cellData.category}</p>
  //     <p>Region: {cellData.region}</p>
  //     <p>Value: {cellData.value}</p>
  //     <p>Rows: 
  //       <table>
  //         <tr>
  //           <th>feed</th>
  //           <th>primary company</th>
  //           <th>primary person</th>
  //           <th>sales promotion</th>
  //           <th>sentiment</th>
  //           <th>tldr</th>
  //           <th>technical level</th>
  //         </tr>
  //       {cellData.rows.map((row) => (
  //         <tr>
  //           <td>{row.feedId}</td>
  //           <td>{row.primaryCompany}</td>
  //           <td>{row.primaryPerson}</td>
  //           <td>{row.salesPromotion}</td>
  //           <td>{row.sentiment}</td>
  //           <td>{row.singleLineTldr}</td>
  //           <td>{row.technicalLevel}</td>
  //         </tr>
  //       ))}
  //       </table>
  //     </p>
  //   </div>
  // );
}

export default FeedsTabs;
