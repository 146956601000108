import React, { useEffect, useState } from 'react';
import { apiBase, getApiData } from './api';

const date = require('date-and-time');


function PodcastChecker({ setPodcastSource, setPodcastList, username, idToken }) {
    const [lastUpdated, setLastUpdated] = useState(new Date);

  useEffect(() => {
    const checkForPodcasts = async () => {
        console.log('This function is called every 10 seconds (' + username + ')');

        const podcastGetTodayUrl = `${apiBase()}/m/get_podcast_audio/` + username;
        await fetch(podcastGetTodayUrl, {
            headers: {
            'Auth': `Bearer ${idToken}`
            }
          }
        );

        const podcastListUrl = `${apiBase()}/m/fetch_podcasts_for_user/` + username; //mike.davis.20230422.220701";

        console.log(podcastListUrl);

        const fetchResponse = await fetch(podcastListUrl, {
            headers: {
            'Auth': `Bearer ${idToken}`
            }
          }
        );

        const data = await fetchResponse.json();
        // const url = fetchResponse.config.url;

        // const podcasts = JSON.parse(data);
        setLastUpdated(new Date);
        setPodcastList(data);
    };

    console.log("MOO" + username);

    const intervalID = setInterval(checkForPodcasts, 10000);

    checkForPodcasts();

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(intervalID);
    };
  }, []); // Pass an empty dependency array to ensure this effect runs only once when the component mounts

  return (
    <div>
      <p>Last update: {date.format(lastUpdated, "YYYY-MM-DD HH:mm:ss")}</p>
    </div>
  );
}

export default PodcastChecker;
