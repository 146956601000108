import React from 'react';
import './TwoColumns.css';

import PodcastList from './PodcastList';
import PodcastCategoryList from './PodcastCategoryList';

const TwoColumns = ({ podcasts, setPodcast, podcastCategories, setPodcastCategory }) => {
  return (
    <div className="container">
      <div className="column column-1">
          <PodcastList items={podcasts} onButtonClick={setPodcast}></PodcastList>
      </div>
      <div className="column column-2">
          <PodcastCategoryList items={podcastCategories} onButtonClick={setPodcastCategory}></PodcastCategoryList>
      </div>
    </div>
  );
};

export default TwoColumns;
