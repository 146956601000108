// src/ButtonList.js
import React from 'react';

const PodcastList = ({ items, onButtonClick }) => {
  return (
    <ul>
      {items && items.map && items.map((item, index) => (
        <li>
        <button key={index} onClick={() => onButtonClick(item)}>
          {item.startDatetime}
        </button>
        </li>
      ))}
    </ul>
  );
};

export default PodcastList;
