import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import GridLayout from "react-grid-layout";

import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';

ChartJS.register(ArcElement, Tooltip, Legend);

export function Regions({regions}) {
  const sortedRegions = Object.keys(regions).sort((a,b) => regions[b]['total'] - regions[a]['total']);
  const columnCount = 4;
  const mainHeight = 2;
  const mainCount = 3;

  const getPiLayout = (index) => {
    if(index < mainCount) { // focus on the top 3
      return { 
        i: index, 
        x: 0, 
        y: index, 
        w: columnCount, 
        h: mainHeight, 
        minW: columnCount, 
        minH: mainHeight 
      };
    } else {
      return { 
        i: index, 
        x: (index - mainCount) % columnCount, 
        y: (mainCount * mainHeight) + Math.floor(index / columnCount), 
        w: 1, 
        h: 1, 
        minW: 1, 
        minH: 1 
      };
    }
  }
  
  // const [data, setData] = useState({
  //   labels: sortedRegions,
  //   datasets: [
  //       {
  //           id: 1,
  //           label: '# of Votes',
  //           data: sortedRegions.map((region) => regions[region]['total']),
  //           backgroundColor: [
  //             '#FF6384', // Light Red
  //             '#36A2EB', // Light Blue
  //             '#FFCE56', // Yellow
  //             '#4BC0C0', // Turquoise
  //             '#9966FF', // Purple
  //             '#FF9F40', // Orange
  //             '#E7E9ED', // Light Grey
  //             '#4B7BEC', // Indigo
  //             '#20C997', // Teal
  //             '#FD7E14'  // Dark Orange
  //           ],
  //           // // borderColor: 'rgba(75,192,192,1)',
  //           // borderWidth: 1,
  //       },
  //   ]}
  // );
  
  return (
    <div>
      <h2>Regions</h2>
      <Pie
          datasetIdKey='id'
          data={{
            labels: Object.keys(regions).sort((a,b) => regions[b]['total'] - regions[a]['total']),
            datasets: [{
                id: 1,
                label: '# of Votes',
                data: Object.keys(regions).sort((a,b) => regions[b]['total'] - regions[a]['total']).map((region) => regions[region]['total']),
                backgroundColor: [
                  '#FF6384', // Light Red
                  '#36A2EB', // Light Blue
                  '#FFCE56', // Yellow
                  '#4BC0C0', // Turquoise
                  '#9966FF', // Purple
                  '#FF9F40', // Orange
                  '#E7E9ED', // Light Grey
                  '#4B7BEC', // Indigo
                  '#20C997', // Teal
                  '#FD7E14'  // Dark Orange
              ],
            },
          ]}} 
          options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: true,
                  text: 'Chart.js Bar Chart',
                },
              },
          }}
      />

      <h2>Categories</h2>
      <GridLayout
        className="layout"
        // layout={layout}
        cols={3}
        rowHeight={300}
        width={1200}
      >
        {sortedRegions.map((region, index) => (
          <div key={index} data-grid={getPiLayout(index)}>
          <Pie 
            datasetIdKey='id'
            data={{
              labels: Object.keys(regions[region]['lookupCategory']).sort((a,b) => regions[region]['lookupCategory'][b]['total'] - regions[region]['lookupCategory'][a]['total']),
              datasets: [
                  {
                      id: 1,
                      label: '# of Votes',
                      data: Object.keys(regions[region]['lookupCategory']).sort((a,b) => regions[region]['lookupCategory'][b]['total'] - regions[region]['lookupCategory'][a]['total']).map((lookupCategory) => regions[region]['lookupCategory'][lookupCategory]['total']),
                      // data: []// Object.keys(regions[region]['lookupCategory']).map((lookupCategory) => regions[region]['lookupCategory'][lookupCategory]['total']),
                      backgroundColor: [
                        '#FF6384', // Light Red
                        '#36A2EB', // Light Blue
                        '#FFCE56', // Yellow
                        '#4BC0C0', // Turquoise
                        '#9966FF', // Purple
                        '#FF9F40', // Orange
                        '#E7E9ED', // Light Grey
                        '#4B7BEC', // Indigo
                        '#20C997', // Teal
                        '#FD7E14'  // Dark Orange
                    ],
                  //   backgroundColor: [
                  //     '#708090', // SlateGray
                  //     '#778899', // LightSlateGray
                  //     '#2F4F4F', // DarkSlateGray
                  //     '#696969', // DimGray
                  //     '#808080', // Gray
                  //     '#A9A9A9', // DarkGray
                  //     '#C0C0C0', // Silver
                  //     '#D3D3D3', // LightGray
                  //     '#191970', // MidnightBlue
                  //     '#708090'  // SlateGray
                  // ]
                  
                      // // borderColor: 'rgba(75,192,192,1)',
                      // borderWidth: 1,
                  },
              ]}} 
            options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  title: {
                    display: true,
                    text: `Region ${region} lookup categories`,
                  },
                },
            }}
            />
          </div>
        ))}
      </GridLayout>
    </div>
  );
}
