// src/InputBox.js
import React, { useState } from 'react';

const InputBox = ({ inputValue, handleChange, handleKeyPress }) => {
  return (
    <div>
      <input type="text" value={inputValue} onChange={handleChange} onKeyPress={handleKeyPress} />
    </div>
  );
};

export default InputBox;
