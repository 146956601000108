import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { LoadingWrapper } from './LoadingWrapper';

ChartJS.register(ArcElement, Tooltip, Legend);

export function GeneralCategories({generalCategories}) {
    generalCategories ||= [];

  return (
    // <LoadingWrapper loadingData={generalCategories}>
    <div>
        <h2>General Categories</h2>
        <Pie
           datasetIdKey='id'
            data={{
                labels: Object.keys(generalCategories).sort((a,b) => generalCategories[a] - generalCategories[b]),
                datasets: [
                    {
                        id: 1,
                        label: '# of Votes',
                        data: Object.keys(generalCategories).sort((a,b) => generalCategories[a] - generalCategories[b]).map((gc) => generalCategories[gc]),
                        backgroundColor: [
                            '#FF6384', // Light Red
                            '#36A2EB', // Light Blue
                            '#FFCE56', // Yellow
                            '#4BC0C0', // Turquoise
                            '#9966FF', // Purple
                            '#FF9F40', // Orange
                            '#E7E9ED', // Light Grey
                            '#4B7BEC', // Indigo
                            '#20C997', // Teal
                            '#FD7E14'  // Dark Orange
                        ],
                        // // borderColor: 'rgba(75,192,192,1)',
                        // borderWidth: 1,
                    },
            ]}} 
            options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                  title: {
                    display: true,
                    text: 'Chart.js Bar Chart',
                  },
                },
            }}
        />
    </div>
    // </LoadingWrapper>
  );
}
