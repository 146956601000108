import React, { useState, useRef, useEffect } from 'react';
import './SettingsDropdown.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'

import InputBox from './InputBox';
import { apiBase, getApiData } from './api';

const SettingsDropdown = ({username, setUsername, setCheckPodcasts, targetAudience, setTargetAudience, voice, setVoice, language, setLanguage}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (event) => {
    const username = event.target.value;

    setUsername(username);

    localStorage.setItem('username', username);
  };

  const handleKeyPress = (event) => {
    if(event.key === 'Enter') {
      setCheckPodcasts(true);
    }
  };

  const handleVoiceChange = (event) => {
    const voice = event.target.value;

    // setTargetAudience(targetAudience);
    setVoice(voice);
  };

  const handleVoiceKeyPress = (event) => {
    if(event.key === 'Enter') {
        const voice = event.target.value;

        setVoice(voice);

        try {
            const setVoiceUrl = `${apiBase()}/m/set_voice/` + username;
            const response = fetch(setVoiceUrl, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ voice: voice }),
            }).then(async (fetchResponse) => {
                const setVoiceResponse = await fetchResponse.json();
                console.log(setVoiceResponse);
            });
        } catch(e) {
            console.error(e);
        }
    }
  };

  const handleTargetAudienceChange = (event) => {
    const targetAudience = event.target.value;

    setTargetAudience(targetAudience);
  };

  const handleTargetAudienceKeyPress = (event) => {
    if(event.key === 'Enter') {
        const targetAudience = event.target.value;
        setTargetAudience(targetAudience);

        try {
            const setVoiceUrl = `${apiBase()}/m/set_target_audience/` + username;
            const response = fetch(setVoiceUrl, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ targetAudience: targetAudience }),
            }).then(async (fetchResponse) => {
                const setVoiceResponse = await fetchResponse.json();
                console.log(setVoiceResponse);
            });
        } catch(e) {
            console.error(e);
        }

    }
  };

  const handleLanguageChange = (event) => {
    const language = event.target.value;

    setLanguage(language);
  };

  const handleLanguageKeyPress = (event) => {
    if(event.key === 'Enter') {
        const language = event.target.value;

        setLanguage(language);

        try {
            const setVoiceUrl = `${apiBase()}/m/set_language/` + username;
            const response = fetch(setVoiceUrl, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ language: language }),
            }).then(async (fetchResponse) => {
                const setVoiceResponse = await fetchResponse.json();
                console.log(setVoiceResponse);
            });
        } catch(e) {
            console.error(e);
        }

    }
  };

  return (
    <div className="settings-dropdown" ref={dropdownRef}>
     <FontAwesomeIcon icon={faCog} />
      <button className="settings-button" onClick={toggleDropdown}>
        Settings
      </button>
      {isOpen && (
        <ul className="settings-menu">
          <li className="settings-item">
          Username <InputBox inputValue={username} handleChange={handleChange} handleKeyPress={handleKeyPress}></InputBox>
          </li>
          <li className="settings-item">
            About Me <InputBox inputValue={targetAudience} handleChange={handleTargetAudienceChange} handleKeyPress={handleTargetAudienceKeyPress}></InputBox>
          </li>
          <li className="settings-item">
            Voice <InputBox inputValue={voice} handleChange={handleVoiceChange} handleKeyPress={handleVoiceKeyPress}></InputBox>
          </li>
          <li className="settings-item">
            Language <InputBox inputValue={language} handleChange={handleLanguageChange} handleKeyPress={handleLanguageKeyPress}></InputBox>
          </li>
        </ul>
      )}
    </div>
  );
};

export default SettingsDropdown;
