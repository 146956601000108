import React, { useEffect, useState } from 'react';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  

const HourlyCountGraph = ({countsPerHour}) => {
  const [data, setData] = useState(
    
  );

//   useEffect(() => {
//     console.log(countsPerHour);
//     console.log(Object.keys(countsPerHour));
//     console.log(Object.values(countsPerHour));

//     setData({
//       labels: Object.keys(countsPerHour),
//       datasets: [
//         {
//             id: 1,
//           label: '# of Counts',
//           data: Object.values(countsPerHour),
//         //   backgroundColor: 'rgba(75,192,192,0.6)',
//         //   borderColor: 'rgba(75,192,192,1)',
//         //   borderWidth: 1,
//         },
//       ],
//     });
//   }, []);

  return (
    <div>
      <h2>Counts Per Hour</h2>
      <Bar
  datasetIdKey='id'
  data={{
    labels: Object.keys(countsPerHour),
    datasets: [
      {
          id: 1,
        label: '# of Counts',
        data: Object.values(countsPerHour),
        backgroundColor: [
            // '#FF6384', // Light Red
            // '#36A2EB', // Light Blue
            // '#FFCE56', // Yellow
            // '#4BC0C0', // Turquoise
            // '#9966FF', // Purple
            '#FF9F40', // Orange
            // '#E7E9ED', // Light Grey
            // '#4B7BEC', // Indigo
            // '#20C997', // Teal
            // '#FD7E14'  // Dark Orange
        ],

      //   backgroundColor: 'rgba(75,192,192,0.6)',
      //   borderColor: 'rgba(75,192,192,1)',
      //   borderWidth: 1,
      },
    ],
  }}
  options={{
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
  }}></Bar>

    </div>
  );
};

export default HourlyCountGraph;
