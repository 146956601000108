import 'react-data-grid/lib/styles.css';

import DataGrid from 'react-data-grid';

import axios from 'axios';
import { apiBase, getApiData } from './api';


// const columns = [
//   { key: 'id', name: 'ID' },
//   { key: 'title', name: 'Title' }
// ];

// const rows = [
//   { id: 0, title: 'Example' },
//   { id: 1, title: 'Demo' }
// ];

function CategoryGrid({generalCategories, categoryGridData, mainRegion, setSelectedCellData, idToken}) {
  console.log(categoryGridData);

  const itemTotal = categoryGridData.length;

  const primaries = {};
  for(var item of categoryGridData) {
    primaries[item.primary_person] ||= 0;
    primaries[item.primary_company] ||= 0;

    primaries[item.primary_person]++;
    if(item.primary_person !== item.primary_company) {
      primaries[item.primary_company]++;
    }

    if(!item.category) {
      item.category = 'NONE';
      console.log(item);
    }
  }

  const categoryStats = {};
  for(var item of categoryGridData) {
    categoryStats[item.category] ||= 0;

    categoryStats[item.category]++;
  }

  const categoryStatsSorted = Object.keys(categoryStats).sort((a,b) => categoryStats[b] - categoryStats[a]);

  const primaryColumns = Object.keys(primaries)
    .sort((a,b) => primaries[b] - primaries[a])
    .filter((primary) => primaries[primary] > 2)
    .map(category => ({ key: category, name: category }));

  const columns = [
    { key: 'category', name: 'Category', frozen: true, width: 50, minWidth: 5, resizable: true },
    { key: 'ALL', name: 'ALL', frozen: true, width: 50, minWidth: 5, resizable: true },
    ...primaryColumns,
  ];

  const firstRow = {
    'category': 'ALL',
    'ALL': itemTotal,
  };

  for(var primaryColumn of primaryColumns) {
    firstRow[primaryColumn.key] = primaries[primaryColumn.key];
  };

  const rows = [
    firstRow,
  ];

  for(var category of categoryStatsSorted) {
    const row = {
      'category': category,
    };

    var categoryTotal = 0;
    for(var primaryColumn of primaryColumns) {
      const primaryCategoryTotal = categoryGridData.filter((item) => item.category === category && (item.primary_person === primaryColumn.key || item.primary_company === primaryColumn.key)).length;
      row[primaryColumn.key] = primaryCategoryTotal;
      categoryTotal += primaryCategoryTotal;
    };
    row['ALL'] = categoryTotal;

    if(categoryTotal > 2) {
      rows.push(row);
    }
  }

  console.log(`row count (${rows.length})`);

  // for(var region of Object.keys(mainRegion).sort((a,b) => mainRegion[b].total - mainRegion[a].total)) {
  //   const row = {
  //     'region': region,
  //   };
  //   for(var category of Object.keys(generalCategories).sort((a,b) => generalCategories[b] - generalCategories[a])) {
  //     row[category] = mainRegion[region].lookupCategory[category]?.total || 0;
  //   }

  //   rows.push(row);
  // }

//   console.log(rows[0]);

  // const rows = Object.keys(mainRegion)
  //   .sort((a,b) => mainRegion[a].total - mainRegion[b].total)
  //   .map((region, index) => ({ index: index, title: mainRegion[region].lookupCategory }) )

  return <DataGrid

    columns={columns}
    rows={rows}
    defaultColumnOptions={{
      sortable: true,
      resizable: true
    }}
    onCellClick={(params) => {
      // console.log(`params.id (${JSON.stringify(params.id)})`);
      // console.log(`params.field (${JSON.stringify(params.field)})`);
      // console.log(`params.value (${JSON.stringify(params.value)})`);
      console.log(`params keys (${Object.keys(params)})`);
      console.log(`params row (${Object.keys(params.row)})`);
      console.log(`params column (${Object.keys(params.column)})`);
      console.log(`params key (${params.column.key})`);
      console.log(`params name (${params.column.name})`);
      // console.log(`params selectCell (${params.column.selectCell})`);

      console.log(`params value (${params.row[params.column.key]})`);

      console.log(`params category (${params.row['category']})`);

      setSelectedCellData({ 
        value: params.row[params.column.key], 
        primary: params.column.key, 
        category: params.row['category'], 
        rows: categoryGridData,
      });

      // console.log(`col.key (${JSON.stringify(col.key)})`);
      // console.log(`col.name (${JSON.stringify(col.name)})`);
      // console.log(`col keys (${Object.keys(col)})`);
      // setSelectedCellData({ value: rows[idx][col.key], column: col.name });
      // setSelectedCellData({ value: params.row[params.column.key], category: params.column.key, region: params.row['region']});

      // const feedTopicSummaryUrl = `${apiBase()}/m/get_feed_topic_rows/${params.column.key}`;
      // console.log(`feedTopicSummaryUrl (${feedTopicSummaryUrl})`);

      // axios.get(feedTopicSummaryUrl, {
      //   headers: {
      //     'Auth': `Bearer ${idToken}`
      //   }
      // }).then(async (fetchResponse) => {
      //   const topicData = fetchResponse.data;

      //   console.log(topicData);
  
      //   // console.log(topicData.mainRegion[params.row['region']].rows);

      //   // setSelectedCellData({ value: params.row[params.column.key], category: params.column.key, region: params.row['region'], rows: topicData.mainRegion[params.row['region']].rows});
      // });
    }}
  />;
}

export default CategoryGrid;
