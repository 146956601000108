import logo from './logo.svg';
import './App.css';

import LoginPage from './LoginPage'

import React, { useEffect } from 'react';
import { useRef, useState } from 'react';

import FeedsTabs from './FeedsTabs';

function App() {
  const [loggedIn, setIsLoggedIn] = useState(false);
  const [idToken, setIdToken] = useState(sessionStorage.getItem('auth-id-token'));
  const [refreshToken, setRefreshToken] = useState(sessionStorage.getItem('auth-refresh-token'));

  useEffect(() => {
    // Check session data on component mount
    const authIdToken = sessionStorage.getItem('auth-id-token');
    if (authIdToken) {
      setIdToken(authIdToken);
      setIsLoggedIn(true);
    }

    const authRefreshToken = sessionStorage.getItem('auth-refresh-token');
    if(authRefreshToken) {
      setRefreshToken(authRefreshToken);
    }
  }, []);

  return (
    <div className="App">

      {loggedIn && 
      <FeedsTabs idToken={idToken} setIsLoggedIn={setIsLoggedIn} isLoggedIn={loggedIn}></FeedsTabs>
      }
      {(!loggedIn) &&
      <LoginPage setIsLoggedIn={setIsLoggedIn} setIdToken={setIdToken} refreshToken={refreshToken}></LoginPage>
      }
    </div>
  );
}

export default App;
